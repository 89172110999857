import type { RefObject } from 'react'
import React, { memo } from 'react'

import { CarouselCursor } from './carousel-cursor'
import { CarouselNavigation } from './carousel-navigation'
import { useCarouselContext } from './context'

export interface CarouselControllersProps {
  interval?: number
  slidesContainerRef: RefObject<HTMLDivElement>
}

export const CarouselControllers = memo<CarouselControllersProps>(
  ({ interval, slidesContainerRef }) => {
    const {
      state: { totalSlides, visibleSlides },
    } = useCarouselContext();

    const shouldRenderControllers = totalSlides > visibleSlides

    return shouldRenderControllers ? (
      <>
        <CarouselNavigation interval={interval} />
        <CarouselCursor slidesContainerRef={slidesContainerRef} />
      </>
    ) : null
  }
)
