import React, { memo, useEffect, useLayoutEffect, useRef } from 'react'

import { useCarouselContext } from './context'

export interface CarouselNavigationProps {
  interval?: number
}

const ANIMATION_KEYFRAMES = [
  { transform: 'scaleX(0)' },
  { transform: 'scaleX(1)' },
]

export const CarouselNavigation = memo<CarouselNavigationProps>(
  ({ interval }) => {
    const figureRef = useRef<HTMLElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const {
      state: { currentSlide, totalSlides, isPlaying },
      setCurrentSlide,
    } = useCarouselContext()

    const navigationItems = Array.from({ length: totalSlides }, (_, index) => (
      <div
        key={index}
        role="button"
        tabIndex={-1}
        onClick={() => setCurrentSlide(index)}
        style={{ flex: 1 }}
      >
        {(index + 1).toString().padStart(2, '0')}
      </div>
    ))

    useLayoutEffect(() => {
      const container = containerRef.current
      if (container) {
        container.style.setProperty('--carousel-total-slides', `${totalSlides}`)
        container.style.setProperty(
          '--carousel-current-slide',
          `${currentSlide}`
        )
      }
    }, [totalSlides, currentSlide])

    useEffect(() => {
      const figure = figureRef.current

      if (interval && figure) {
        const animation = figure.animate(ANIMATION_KEYFRAMES, {
          duration: isPlaying ? interval : 0,
          iterations: isPlaying ? Infinity : 1,
          fill: 'forwards',
        })

        return () => {
          animation.cancel()
          figure.style.animation = 'none'
        }
      }
    }, [currentSlide, interval, isPlaying])

    return (
      <div className="carousel__navigation-container" ref={containerRef}>
        <div className="carousel__navigation">
          <figure ref={figureRef} className="carousel__navigation-bar" />
        </div>

        <div className="carousel__navigation-items">{navigationItems}</div>
      </div>
    )
  }
)
