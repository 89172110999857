import '../../styles/components/carousel-cursor.scss'

import React from 'react'

export enum ArrowPositions {
  LEFT = 'left',
  RIGHT = 'right',
  DRAG = 'drag',
}

export interface CarouselCursorIconProps {
  arrowPosition?: ArrowPositions
  variant?: 'dynamic' | 'fixed'
  [x: string]: any
}

export const CarouselCursorIcon: React.FC<CarouselCursorIconProps> = ({
  arrowPosition = ArrowPositions.RIGHT,
}) => {
  const isDragPosition = arrowPosition === ArrowPositions.DRAG
  const dragClass = isDragPosition ? '-drag' : ''

  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="carousel-coursor"
    >
      <circle cx="22.6938" cy="23.2002" r="22.5" />
      {(arrowPosition === ArrowPositions.RIGHT || isDragPosition) && (
        <path
          className={`carousel-coursor__position-right${dragClass}`}
          d="M19.5688 16.9502L25.8188 23.2002L19.5688 29.4502"
          stroke="white"
        />
      )}

      {(arrowPosition === ArrowPositions.LEFT || isDragPosition) && (
        <path
          className={`carousel-coursor__position-left${dragClass}`}
          d="M19.5688 16.9502L25.8188 23.2002L19.5688 29.4502"
          stroke="white"
        />
      )}
    </svg>
  )
}
