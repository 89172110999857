import type { RefObject } from 'react'
import { useRef } from 'react'
import { useLayoutEffect, useState } from 'react'
import React from 'react'

import { CarouselCursorIcon, ArrowPositions } from './carousel-cursor-icon'
import { useCarouselContext } from './context'

export interface CarouselCursorProps {
  slidesContainerRef: RefObject<HTMLDivElement>
}

const MIN_PRESSING_TIME_FOR_DRAG_MS = 200

export const CarouselCursor: React.FC<CarouselCursorProps> = ({
  slidesContainerRef,
}) => {
  const [cursorPosition, setCursorPosition] = useState<ArrowPositions>(
    ArrowPositions.LEFT
  )
  const cursorContainerRef = useRef<HTMLDivElement>(null)
  const { onNextSlide, onPreviousSlide } = useCarouselContext()

  useLayoutEffect(() => {
    const slidesContainer = slidesContainerRef.current
    const cursorContainer = cursorContainerRef.current

    if (!slidesContainer || !cursorContainer) return

    const mutableRef = {
      x: 0,
      y: 0,
      isPressing: null as null | boolean,
      isPressingChangedAt: Infinity,
      wasMovedWhilePressing: false,
    }

    const hideContainer = () => {
      cursorContainer.style.position = 'absolute'
      cursorContainer.style.display = 'none'
    }

    const showContainer = () => {
      cursorContainer.style.display = 'block'
      cursorContainer.style.pointerEvents = 'none'
    }

    hideContainer()

    const calculateRelativeDimensions = () => {
      const {
        left: containerLeft,
        top: containerTop,
        width,
      } = slidesContainer.getBoundingClientRect()

      const left = mutableRef.x - containerLeft
      const top = mutableRef.y - containerTop

      const isLeft = width - left > width / 2

      return { container: { width }, cursor: { left, top, isLeft } }
    }

    const updatePosition = (() => {
      let positionUpdateScheduled = false

      return () => {
        if (!positionUpdateScheduled) {
          positionUpdateScheduled = true

          requestAnimationFrame(() => {
            const {
              cursor: { left, top },
            } = calculateRelativeDimensions()

            cursorContainer.style.top = `${top}px`
            cursorContainer.style.left = `${left}px`

            positionUpdateScheduled = false
          })
        }
      }
    })()

    const updateCursorState = (() => {
      let cursorStateUpdateScheduled = false

      return (force = false) => {
        if (!cursorStateUpdateScheduled || force) {
          cursorStateUpdateScheduled = true

          requestAnimationFrame(() => {
            if (
              mutableRef.isPressing &&
              performance.now() - mutableRef.isPressingChangedAt >
                MIN_PRESSING_TIME_FOR_DRAG_MS
            ) {
              setCursorPosition(ArrowPositions.DRAG)
              return
            }

            const {
              cursor: { isLeft },
            } = calculateRelativeDimensions()

            setCursorPosition(
              isLeft ? ArrowPositions.LEFT : ArrowPositions.RIGHT
            )

            cursorStateUpdateScheduled = false
          })
        }
      }
    })()

    const handleClick = () => {
      if (
        !mutableRef.wasMovedWhilePressing ||
        (mutableRef.isPressing &&
          performance.now() - mutableRef.isPressingChangedAt <=
            MIN_PRESSING_TIME_FOR_DRAG_MS)
      ) {
        const {
          cursor: { isLeft },
        } = calculateRelativeDimensions()

        if (isLeft) {
          onPreviousSlide()
        } else {
          onNextSlide()
        }
      }
    }

    const setIsPressing = (isPressing: boolean) => {
      const changed = mutableRef.isPressing !== isPressing

      if (changed) {
        mutableRef.isPressing = isPressing
        mutableRef.isPressingChangedAt = performance.now()
        mutableRef.wasMovedWhilePressing = false
      }
    }

    const setWasMovedWhilePressing = () => {
      if (mutableRef.isPressing) {
        mutableRef.wasMovedWhilePressing = true
      }
    }

    const onMouseMove = (e: MouseEvent) => {
      mutableRef.x = e.x
      mutableRef.y = e.y

      showContainer()
      updatePosition()
      updateCursorState()
      setWasMovedWhilePressing()
    }
    const onMouseEnter = () => {
      showContainer()
      updateCursorState()
    }
    const onMouseLeave = () => {
      hideContainer()
      updateCursorState()
    }

    const onMouseDown = () => {
      setIsPressing(true)

      updateCursorState()
    }

    const onMouseUp = () => {
      handleClick()
      setIsPressing(false)

      updateCursorState(true)
    }

    slidesContainer.addEventListener('mousemove', onMouseMove)
    slidesContainer.addEventListener('mouseenter', onMouseEnter)
    slidesContainer.addEventListener('mouseleave', onMouseLeave)
    slidesContainer.addEventListener('mousedown', onMouseDown)
    document.body.addEventListener('mouseup', onMouseUp)

    return () => {
      slidesContainer.removeEventListener('mousemove', onMouseMove)
      slidesContainer.removeEventListener('mouseenter', onMouseEnter)
      slidesContainer.removeEventListener('mouseleave', onMouseLeave)
      slidesContainer.removeEventListener('mousedown', onMouseDown)
      document.body.removeEventListener('mouseup', onMouseUp)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slidesContainerRef])

  return (
    <div ref={cursorContainerRef}>
      <CarouselCursorIcon arrowPosition={cursorPosition} />
    </div>
  )
}
